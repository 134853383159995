import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CardSearch from './components/cardSearch/CardSearch';
import SavedList from './components/savedList/SavedList';
import ImageUpload from './pages/ImageUpload';
import CardsByCollectionPage from './pages/CardsByCollection';
import CardSearchPage from './pages/CardSearchPage';
import StatisticsPage from './pages/StatisticsPage';
import GoogleLoginComponent from './components/googleLogin/GoogleLoginComponent';
import ProfilePage from './pages/ProfilePage';
import MyCollectionsList from './pages/MyCollectionsList';
import DeckSearch from './pages/DeckSearch';
import SalesPurchasesPage from './pages/SalesPurchasesPage';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);

  // Cards saved by the user
  const [savedCards, setSavedCards] = useState(() => {
    const sessionId = localStorage.getItem('sessionId') || crypto.randomUUID();
    localStorage.setItem('sessionId', sessionId);
    let savedData = localStorage.getItem('savedCards');
    savedData = savedData === 'undefined' ? null : savedData;
    return savedData ? JSON.parse(savedData) : {};
  });

  // Toggles for mobile sidebars
  const [showMenu, setShowMenu] = useState(false);   // Left sidebar
  const [showSaved, setShowSaved] = useState(false); // Right sidebar

  // Firebase auth
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        const userData = {
          uid: firebaseUser.uid,
          name: firebaseUser.displayName,
          email: firebaseUser.email,
          picture: firebaseUser.photoURL,
        };
        setUser(userData);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Logout
  const handleLogout = () => {
    auth.signOut().catch((error) => {
      console.error('Logout error:', error);
    });
  };

  // Sync savedCards with localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      try {
        let savedData = localStorage.getItem('savedCards');
        savedData = savedData === 'undefined' ? null : savedData;
        const updatedCards = savedData ? JSON.parse(savedData) : {};
        setSavedCards(updatedCards);
      } catch (error) {
        console.error('Error parsing savedCards from localStorage:', error);
        setSavedCards({});
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <Router>
      <div className="App">
        {/* NAVBAR FIJA ARRIBA */}
        <nav className="navbar">
          <div className="navbar-left-desktop">
            {/* Logo o nombre del sitio */}
            <Link to="/" className="brand-link">
              Alto Buscador
            </Link>
          </div>

          {/* Parte derecha del navbar: saludo de usuario o link a Login */}
          {/* <div className="navbar-right">
            {user ? (
              <>
                <span className="welcome-text">
                  Bienvenido, {user.name || user.email}!
                </span>
                <button onClick={handleLogout} className="logout-button">
                  Logout
                </button>
              </>
            ) : (
              <Link to="/login" className="nav-link">
                Login
              </Link>
            )}
          </div> */}

          {/* Botones móviles: abren/cerran sidebars */}
          <div className="navbar-toggles">
            <button
              className="toggle-button"
              onClick={() => {
                setShowMenu(!showMenu);
                setShowSaved(false);
              }}
            >
              ☰
            </button>
            <button
              className="toggle-button"
              onClick={() => {
                setShowSaved(!showSaved);
                setShowMenu(false);
              }}
            >
              🗃
            </button>
          </div>
        </nav>

        {/* SIDEBAR IZQUIERDA (Menú) */}
        <aside className={`sidebar-menu ${showMenu ? 'menu-open' : ''}`}>
          <div className="menu-links">
            {/* Sección de Búsqueda SIEMPRE visible (logueado o no) */}
            <h4>Búsqueda</h4>
            <Link to="/" onClick={() => setShowMenu(false)}>
              Búsqueda de Singles
            </Link>
            <Link to="/deck" onClick={() => setShowMenu(false)}>
              Búsqueda masiva
            </Link>

            {/* {user && (
              <>
                <h4>Cartas de usuarios</h4>
                <Link to="/cards_by_collection" onClick={() => setShowMenu(false)}>
                  Ver carpetas
                </Link>
                <Link to="/cards" onClick={() => setShowMenu(false)}>
                  Ver singles
                </Link>

                <h4>Mi cuenta</h4>
                <Link to="/mycollections" onClick={() => setShowMenu(false)}>
                  Mis colecciones
                </Link>
                <Link to="/upload" onClick={() => setShowMenu(false)}>
                  Subir una colección
                </Link>
                <Link to="/profile" onClick={() => setShowMenu(false)}>
                  Mi perfil
                </Link>

                <h4>Tradeo</h4>
                <Link to="/transactions" onClick={() => setShowMenu(false)}>
                  Mis reservas
                </Link>
              </>
            )} */}
          </div>
        </aside>

        {/* SIDEBAR DERECHA (Saved List) - SIEMPRE visible o accesible */}
        <aside className={`sidebar-saved ${showSaved ? 'saved-open' : ''}`}>
          <h3>Mis cartas guardadas</h3>
          <SavedList savedCards={savedCards} updateSavedCards={setSavedCards} />
        </aside>

        {/* RUTAS PRINCIPALES */}
        <Routes>
          {/* Siempre accesibles (logueado o no) */}
          <Route
            path="/"
            element={
              <CardSearch savedCards={savedCards} updateSavedCards={setSavedCards} />
            }
          />
          <Route
            path="/deck"
            element={
              <DeckSearch savedCards={savedCards} updateSavedCards={setSavedCards} />
            }
          />
          <Route path="/login" element={<GoogleLoginComponent />} />

          {/* Rutas que pueden verse, pero idealmente solo se navegan si hay login
              (aquí solo las ocultamos del menú; si quieres protegerlas totalmente,
               tendrías que hacer un ProtectedRoute o chequear en el componente) */}
          <Route path="/statistics" element={<StatisticsPage />} />
          <Route path="/upload" element={<ImageUpload />} />
          <Route path="/transactions" element={<SalesPurchasesPage />} />
          <Route path="/cards_by_collection" element={<CardsByCollectionPage />} />
          <Route path="/cards" element={<CardSearchPage />} />
          <Route
            path="/profile"
            element={<ProfilePage user={user} setUser={setUser} />}
          />
          <Route path="/mycollections" element={<MyCollectionsList />} />
        </Routes>

        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
};

export default App;
