// CropImage.js
import React, { useState, useEffect } from 'react';

const CropImage = ({
  binderUrl,
  box,            // { x, y, w, h } from your data
  previewWidth,    // e.g. 150
  previewHeight,   // e.g. 210
  onClick
}) => {
  const [imgSize, setImgSize] = useState(null);

  useEffect(() => {
    if (!binderUrl) return;

    // Load image to get its natural size
    const img = new Image();
    img.src = binderUrl;
    img.onload = () => {
      setImgSize({ width: img.width, height: img.height });
    };
  }, [binderUrl]);

  // If we haven't loaded the image yet, show placeholder
  if (!imgSize) {
    return (
      <div
        style={{
          width: previewWidth,
          height: previewHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#eee'
        }}
      >
        Loading...
      </div>
    );
  }

  // We have the image's natural size in imgSize.
  // Next, we figure out the scale factor so that
  // the box.w x box.h region is scaled to previewWidth x previewHeight.
  const scaleX = previewWidth / box.w;
  const scaleY = previewHeight / box.h;

  return (
    <div
      style={{
        width: previewWidth,
        height: previewHeight,
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid #ccc',
        cursor: onClick ? 'pointer' : 'default'
      }}
      onClick={onClick}
    >
      <img
        src={binderUrl}
        alt="Cropped Binder"
        style={{
          position: 'absolute',
          // Scale the entire image
          width: imgSize.width * scaleX,
          height: imgSize.height * scaleY,
          // Move the top-left corner so that the portion (x, y)
          // lines up with the container’s (0,0)
          left: -box.x * scaleX,
          top: -box.y * scaleY
        }}
      />
    </div>
  );
};

export default CropImage;
