// src/pages/StatisticsPage.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { 
  Grid, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Box,
  IconButton 
} from '@mui/material';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell 
} from 'recharts';
import './StatisticsPage.css';
import { FiUsers, FiActivity, FiSearch, FiTrendingUp } from 'react-icons/fi';

const COLORS = ['#3b82f6', '#60a5fa', '#93c5fd', '#bfdbfe'];

// A reusable Metric Card component
const MetricCard = ({ title, value, icon, trend }) => (
  <div className="glass-card">
    <div className="metric-title">
      {icon} {title}
    </div>
    <div className="metric-value">
      {typeof value === 'function' ? value() : value}
    </div>
    {trend !== undefined && (
      <div className="flex items-center mt-2">
        <span className={`badge ${trend > 0 ? 'text-green-400' : 'text-red-400'}`}>
          {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}%
        </span>
      </div>
    )}
  </div>
);

const StatisticsPage = () => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({});
  const [topCards, setTopCards] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [userStats, setUserStats] = useState({});
  const [searchByHour, setSearchByHour] = useState([]);

  // Fetch all search actions from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'userActions'));
        const dataArray = querySnapshot.docs.map(doc => doc.data());
        setData(dataArray);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // Calculate totals per provider (source distribution)
      const selections = [
        'batikueva', 'madToyz', 'magicDealers', 'magicLair',
        'mercadiaCity', 'pirulo', 'scryfall', 'tutor',
      ];
      const totalsData = selections.reduce((acc, key) => {
        acc[key] = data.filter(item => item.selections && item.selections[key]).length;
        return acc;
      }, {});
      setTotals(totalsData);

      // Calculate recent activity for today in GMT-3
      const todayString = new Date(
        new Date().toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' })
      ).toISOString().split('T')[0];
      const todayData = data.filter(item => item.timestamp.startsWith(todayString));
      // Sort descending by timestamp
      const sortedToday = todayData.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setRecentActivity(sortedToday);

      // Calculate top searched cards in the last 30 days
      const now = new Date();
      const rangeStart = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      const filteredData = data.filter(item => new Date(item.timestamp) >= rangeStart);
      const cardCount = filteredData.reduce((acc, item) => {
        acc[item.input] = (acc[item.input] || 0) + 1;
        return acc;
      }, {});
      const sortedCards = Object.entries(cardCount)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10);
      setTopCards(sortedCards);

      // Calculate peak search hours (using GMT-3)
      const hourCounts = {};
      data.forEach(item => {
        const localDate = new Date(
          new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' })
        );
        const hour = localDate.getHours();
        hourCounts[hour] = (hourCounts[hour] || 0) + 1;
      });
      const hoursData = [];
      for (let i = 0; i < 24; i++) {
        hoursData.push({ hour: i, count: hourCounts[i] || 0 });
      }
      setSearchByHour(hoursData);

      // Calculate user engagement metrics
      const sessionMap = data.reduce((acc, item) => {
        const sessionId = item.sessionId;
        acc[sessionId] = (acc[sessionId] || 0) + 1;
        return acc;
      }, {});
      const sessionCounts = Object.values(sessionMap);
      const totalSessions = sessionCounts.length;
      const recurringSessions = sessionCounts.filter(count => count > 1).length;
      const oneTimeSessions = sessionCounts.filter(count => count === 1).length;
      const engagementPercentage = totalSessions > 0 ? ((recurringSessions / totalSessions) * 100).toFixed(2) : 0;

      setUserStats({
        averageSearches: (sessionCounts.reduce((sum, count) => sum + count, 0) / sessionCounts.length).toFixed(2),
        maxSearches: Math.max(...sessionCounts),
        singleSearchUsers: oneTimeSessions,
        engagementPercentage,
        recurringUsers: recurringSessions,
        oneTimeUsers: oneTimeSessions,
      });
    }
  }, [data]);

  // Prepare pie chart data from totals
  const pieData = Object.entries(totals).map(([name, value]) => ({ name, value }));

  return (
    <Box className="statistics-page">
      <Typography variant="h3" gutterBottom sx={{ 
        fontWeight: 800, 
        textAlign: 'center', 
        color: '#3b82f6',
        mb: 6,
        letterSpacing: '-0.05em'
      }}>
        Analytics Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Key Metrics Row */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={3}>
              <MetricCard 
                title="Active Users" 
                value={() => {
                  const now = new Date();
                  const tenMinutesAgo = new Date(now.getTime() - 10 * 60 * 1000);
                  const activeItems = recentActivity.filter(item => new Date(item.timestamp) >= tenMinutesAgo);
                  const uniqueSessionIds = new Set(activeItems.map(item => item.sessionId));
                  return uniqueSessionIds.size;
                }} 
                icon={<FiUsers className="inline mr-2" />}
                trend={12.4}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <MetricCard 
                title="Total Searches" 
                value={recentActivity.length} 
                icon={<FiSearch className="inline mr-2" />}
                trend={-2.1}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <MetricCard 
                title="Avg. Sessions" 
                value={userStats.averageSearches || 0} 
                icon={<FiActivity className="inline mr-2" />}
                trend={5.8}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <MetricCard 
                title="Top Card" 
                value={topCards[0]?.[0] || 'N/A'} 
                icon={<FiTrendingUp className="inline mr-2" />}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <MetricCard 
                title="Engagement" 
                value={`${userStats.engagementPercentage || 0}%`} 
                icon={<FiUsers className="inline mr-2" />}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Peak Search Hours Chart */}
        <Grid item xs={12} md={8}>
          <div className="glass-card">
            <Typography variant="h6" sx={{ color: '#3b82f6', mb: 2 }}>
              Peak Search Hours (GMT-3)
            </Typography>
            <div className="chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={searchByHour}>
                  <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.1} />
                  <XAxis dataKey="hour" stroke="#64748b" />
                  <YAxis stroke="#64748b" />
                  <Tooltip 
                    contentStyle={{
                      background: '#1e293b',
                      border: 'none',
                      borderRadius: '8px'
                    }}
                  />
                  <Bar dataKey="count" fill="#3b82f6" radius={[4, 4, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Grid>

        {/* Source Distribution Pie Chart */}
        <Grid item xs={12} md={4}>
          <div className="glass-card">
            <Typography variant="h6" sx={{ color: '#3b82f6', mb: 2 }}>
              Source Distribution
            </Typography>
            <div className="chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={2}
                    label={({ name, value }) => `${name}: ${value}`}
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={index} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    contentStyle={{
                      background: '#1e293b',
                      border: 'none',
                      borderRadius: '8px'
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </Grid>

        {/* Recent Activity Table */}
        <Grid item xs={12}>
          <div className="glass-card">
            <Typography variant="h6" sx={{ color: '#3b82f6', mb: 2 }}>
              Recent Activity (GMT-3)
            </Typography>
            <TableContainer className="data-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Search Query</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Timestamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentActivity.slice(0, 20).map((item, index) => (
                    <TableRow key={index} hover sx={{ '&:hover': { bgcolor: 'var(--table-hover)' } }}>
                      <TableCell>{item.input}</TableCell>
                      <TableCell>
                        <span className="badge">
                          {item.selections
                            ? Object.entries(item.selections)
                                .filter(([k, v]) => v)
                                .map(([k]) => k)
                                .join(', ')
                            : 'N/A'}
                        </span>
                      </TableCell>
                      <TableCell>{item.action}</TableCell>
                      <TableCell>
                        {new Date(item.timestamp)
                          .toLocaleTimeString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>

        {/* Most Searched Cards (Past 30 Days) Table */}
        <Grid item xs={12}>
          <div className="glass-card">
            <Typography variant="h6" sx={{ color: '#3b82f6', mb: 2 }}>
              Most Searched Cards (Past 30 Days)
            </Typography>
            <TableContainer className="data-table">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Card Name</TableCell>
                    <TableCell>Searches</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topCards.map(([cardName, count], idx) => (
                    <TableRow key={idx} hover sx={{ '&:hover': { bgcolor: 'var(--table-hover)' } }}>
                      <TableCell>{cardName}</TableCell>
                      <TableCell>{count}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StatisticsPage;
