// src/pages/CardSearchPage.js
import React, { useState, useEffect } from 'react';
import axiosWithGoogleToken, { refreshFirebaseToken } from '../axiosConfig';
import CropImage from '../services/cropImage';  // <-- import the helper
import './CardSearchPage.css';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Preview dimensions for the card crop
const PREVIEW_W = 150;
const PREVIEW_H = 210;

const CardSearchPage = () => {
  const [searchResults, setSearchResults] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchNickname, setSearchNickname] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [modalImage, setModalImage] = useState(null);

  // NEW: Reservation state
  const [reservationCard, setReservationCard] = useState(null);
  const [reservationMessage, setReservationMessage] = useState("");

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      setAuthToken(storedToken);
    } else {
      refreshToken();
    }
  }, []);

  const refreshToken = async () => {
    try {
      const newToken = await refreshFirebaseToken();
      if (newToken) {
        setAuthToken(newToken);
        localStorage.setItem('authToken', newToken);
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

  const handleSearch = async () => {
    if (!authToken) {
      console.error('Usuario no autenticado');
      return;
    }
    setIsLoading(true);
    const params = {};
    if (searchTerm.trim()) params.name = searchTerm.trim();
    if (searchEmail.trim()) params.email = searchEmail.trim();
    if (searchNickname.trim()) params.nickname = searchNickname.trim();

    try {
      const response = await axiosWithGoogleToken.get(`${API_URL}/cards`, {
        headers: { Authorization: `Bearer ${authToken}` },
        params,
      });

      // Group by user email
      const groupedResults = response.data.reduce((acc, card) => {
        const userEmail = card.user?.email || "Unknown User";
        if (!acc[userEmail]) acc[userEmail] = [];
        acc[userEmail].push(card);
        return acc;
      }, {});

      setSearchResults(groupedResults);
    } catch (error) {
      console.error('Error en la búsqueda:', error);
    }
    setIsLoading(false);
  };

  // Function to send a reservation request for a card
  const handleReserve = async (card) => {
    try {
      const response = await axiosWithGoogleToken.post(
        `${API_URL}/cards/${card.id}/reservation_requests`,
        { card_id: card.id, message: reservationMessage },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      alert("Solicitud de reserva enviada con éxito");
      // Clear reservation state
      setReservationCard(null);
      setReservationMessage("");
    } catch (error) {
      console.error("Error sending reservation request:", error);
      alert("Error al enviar la solicitud de reserva");
    }
  };

  // Renders a single card; adds a Reserve button and inline form if needed.
  const renderCardItem = (card) => {
    const { collection_box: box } = card;
    const binderUrl = card.binder_image_url || '';

    let imageContent = null;
    if (box && binderUrl) {
      imageContent = (
        <CropImage
          binderUrl={binderUrl}
          box={box}
          previewWidth={PREVIEW_W}
          previewHeight={PREVIEW_H}
          onClick={() => setModalImage(binderUrl)}
        />
      );
    } else {
      imageContent = (
        <div
          style={{
            width: PREVIEW_W,
            height: PREVIEW_H,
            background: '#f0f0f0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={() => binderUrl && setModalImage(binderUrl)}
        >
          {binderUrl ? "Sin box: ver entera" : "Sin imagen"}
        </div>
      );
    }

    if (viewMode === 'list') {
      return (
        <div key={card.id} className="card-list-item">
          <span className="card-list-name">{card.name}</span>
          <span className="card-list-price">({card.collection_reference_price || '$'})</span>
          <button onClick={() => setReservationCard(card)}>Reserve</button>
        </div>
      );
    } else {
      // For grid/gallery view, render the cropped image and details along with Reserve button.
      return (
        <div key={card.id} className="card-item">
          {imageContent}
          <div className="card-details">
            <h3 className="card-name">{card.name}</h3>
            <p className="card-price">
              Precio: {card.collection_reference_price || '$'}
            </p>
            <button onClick={() => setReservationCard(card)} className="reserve-button">
              Reserve
            </button>
            {/* If this card is selected for reservation, show the inline form */}
            {reservationCard && reservationCard.id === card.id && (
              <div className="reservation-form">
                <textarea
                  placeholder="Mensaje para el dueño..."
                  value={reservationMessage}
                  onChange={(e) => setReservationMessage(e.target.value)}
                />
                <button onClick={() => handleReserve(card)}>Enviar Reserva</button>
                <button onClick={() => { setReservationCard(null); setReservationMessage(""); }}>
                  Cancelar
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="cards-search-container">
      <h1>Buscar singles</h1>
      <div className="search-form">
        <input
          type="text"
          placeholder="Buscar por nombre de carta..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <input
          type="text"
          placeholder="Buscar por email del usuario..."
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Buscar por nickname del usuario..."
          value={searchNickname}
          onChange={(e) => setSearchNickname(e.target.value)}
        />
        <button onClick={handleSearch} disabled={isLoading}>
          {isLoading ? 'Buscando...' : 'Buscar'}
        </button>
        <button
          onClick={() =>
            setViewMode(
              viewMode === 'grid'
                ? 'list'
                : viewMode === 'list'
                ? 'gallery'
                : 'grid'
            )
          }
        >
          {viewMode === 'grid' && 'Ver como Lista'}
          {viewMode === 'list' && 'Ver como Galería'}
          {viewMode === 'gallery' && 'Ver como Tarjetas'}
        </button>
      </div>

      {Object.keys(searchResults).length > 0 ? (
        Object.entries(searchResults).map(([userEmail, cards]) => (
          <div key={userEmail} className="user-group">
            <h2 className="user-name">{userEmail}</h2>
            <div
              className={
                viewMode === 'list'
                  ? 'cards-list'
                  : viewMode === 'grid'
                  ? 'cards-grid'
                  : 'cards-gallery'
              }
            >
              {cards.map((card) => renderCardItem(card))}
            </div>
          </div>
        ))
      ) : (
        <p>No se encontraron cartas.</p>
      )}

      {/* Modal for full-size image */}
      {modalImage && (
        <div className="modal" onClick={() => setModalImage(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Carta agrandada" className="modal-img" />
            <button onClick={() => setModalImage(null)} className="modal-close">
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardSearchPage;
