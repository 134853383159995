const crypto = require('crypto-browserify')
const cheerio = require('cheerio');

const scrapeMTGPirulo = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    // Scrape de las cartas
    $('.product').each((index, product) => {
        const expansion = $(product).find('.category').text().trim();
        const title = $(product).find('.name').text().trim();
        const imageUrl = $(product).find('.image img').attr('src');

        $(product).find('.variant-row').each((indexVariant, productVariant) => {
            const stockStatus = $(productVariant).find('.variant-qty').text().trim();
            if (stockStatus.toLowerCase().includes('out of stock')) {
                return; // Omitir este producto porque está agotado
            }
            const price = $(productVariant).find('.price').text().trim();
            const condition = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-variant').value
            const id = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-vid').value

            if (scrapedData.some(card => card.id === id)) {
                return; // Omitir este producto porque está repetido
            }


            scrapedData.push({
                id,
                title,
                price,
                imageUrl,
                condition,
                expansion,
                link: url
            });    
        });
    });    
    return scrapedData
};


const scrapeMagicDealers = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.product').each((index, product) => {
        const title = $(product).find('.name').text().trim();
        const imageUrl = $(product).find('.image img').attr('src');
        const expansion = $(product).find('.category').text().trim();
        $(product).find('.variant-row').each((indexVariant, productVariant) => {
            const stockStatus = $(productVariant).find('.variant-qty').text().trim();
        

            if (stockStatus.toLowerCase().includes('out of stock')) {
                return; // Skip this item because it's out of stock
            }
            const price = $(productVariant).find('.price').text().trim();
            const condition = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-variant').value
            // Create a unique ID by hashing the title and price
            const id = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-vid').value

            if (scrapedData.some(card => card.id === id)) {
                return; // Omitir este producto porque está repetido
            }
    
            scrapedData.push({
                id, 
                title,
                price,
                imageUrl,
                expansion,
                condition,
                link: url
            });
        });
    });

    return scrapedData;
};

const scrapeMagicLair = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.collectionGrid .productCard__card').each((index, element) => {
        const title = $(element).find('.productCard__title a').text().trim();
        const expansion = $(element).find('.productCard__setName').text().trim();
        const imageUrl = $(element).find('.productCard__img').attr('data-src');
        const link = 'https://www.magiclair.com.ar' + $(element).find('.productCard__title a').attr('href');
        
        $(element).find('.productChip').each((indexVariant, productVariant) => {
            if (productVariant.attribs.class.includes('hidden')) {
                return; // Skip this item because it's out of stock
            }
            const price = productVariant.attribs['data-variantprice'] / 100;
            const condition = productVariant.attribs['data-varianttitle'];
            const id = productVariant.attribs['data-variantid'];
            scrapedData.push({
                id,
                title,
                price,
                expansion,
                condition,
                imageUrl,
                link,
            });
        });
    });
    return scrapedData;
};


const scrapeLaBatikuevaStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.js-product-container').each((index, element) => {
        // Check stock status
        const isOutOfStock = $(element).find('.label-default').text().trim() === 'Sin stock';
        if (isOutOfStock) {
            return; // Skip this item because it's out of stock
        }

        const title = $(element).find('.js-item-name').text().trim();

        // Extract and handle image URL
        const imageUrlRelative = $(element).find('.js-item-image').attr('data-srcset').split(' ')[0];
        const imageUrl = `https:${imageUrlRelative}`; // Assuming it needs 'https:' prefix

        // Extract price (modify selector if necessary)
        const price = $(element).find('.item-price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.item-link').attr('href');
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');
        const variants = []
        $(element).find('.js-variation-option').each((index, variant) => { 
            $(variant).find('option').each((optionIndex, option) => variants.push($(option).html()));
        });
        const condition = variants.join(', ');
        scrapedData.push({
            id,
            title,
            price,
            expansion: ' ',
            condition,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};


const parseTheMagicTutor = (html, searchTerm, url) => {
    const $ = cheerio.load(html);
    const textContent = $('xmp').text();
    const lines = textContent.split('\n');

    return lines.map(line => {
        const parts = line.split('\t').map(part => part.trim());
        if (parts.length < 2) return null;

        const name = parts[0];
        const price = parts[1];

        // Check if the line includes the search term and has a valid price
        if (name.toLowerCase().includes(searchTerm.toLowerCase()) && price !== '$ -') {
            const id = crypto.createHash('sha256').update(line).digest('hex');
            return {
                id,
                title: name,
                price: price,
                condition: ' ',
                expansion: ' ',
                imageUrl: '', // Placeholder or empty
                link: url // General link or empty
            };
        }
        return null;
    }).filter(card => card); // Filter out null entries
};

const scrapeMercadiaCityStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.product-item').each((index, element) => {
        const title = $(element).find('.product-item-name').text().trim();
        // Extract and handle image URL
        const imageUrlElement = $(element).find('.main-img');
        
        const imageUrlRelative = imageUrlElement.attr('src');
        
        const condition = $(element).find('.product-item-description').text().trim();

        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';
        
        // Extract price (modify selector if necessary)
        const price = $(element).find('.price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.product-item-link').attr('href');
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');
        scrapedData.push({
            id,
            title,
            price,
            condition,
            expansion: ' ',
            imageUrl,
            link,
        });
    });

    return scrapedData;
};

const scrapeMadToyz = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.products .product').each((index, element) => {
        // Extract title
        const title = $(element).find('.woocommerce-loop-product__title').text().trim();
        const expansion = $(element).find('div a').text().trim();
        // Extract and handle image URL
        const imageUrlElement = $(element).find('img.swiper-lazy');
        const imageUrlRelative = imageUrlElement.attr('src');
        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';

        // Extract price
        let price = $(element).find('.onsale').text().trim().split(':')[1]?.replace(/[a-zA-Z]/g, '');
        
        if (!price) {
            price = $(element).find('bdi').text().trim()?.replace(/[a-zA-Z]/g, '');
        }
        if (!price) {
            price = '$No muestra precio';
        }
        price = `\$${price.replace('$','')}`
        // Log price for debugging
        
        const condition = $(element).find('.badge').text().trim();
        // Extract link
        const link = $(element).find('.woocommerce-LoopProduct-link').attr('href');
        
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');

        scrapedData.push({
            id,
            title,
            price,
            condition,
            expansion,
            imageUrl,
            // collectionUrl: url,
            // profileUrl: 'Mad Toyz',

            link,
        });
    });

    return scrapedData;
};

const parseAltobuscadorAPI = (responseBody, _url) => {
    const parsedCards = []
    responseBody.forEach(element => {
        debugger
        parsedCards.push({
            id: `${element.id}-${element.created_at}`,
            title: element.name,
            price: element.collection_reference_price || 'No especifica precio',
            condition: element.condition || 'No especifica condición',
            expansion: element.set || 'Mira el set en la foto',
            imageUrl: `${element.binder_image_url}`,
            collectionUrl: `${process.env.REACT_APP_BASE_URL}/collections/${element.collection_id}`,
            vendorUrl: `${process.env.REACT_APP_BASE_URL}/users/${element.user?.id}`,
            vendorName: element.contact_info?.nickname || element.user?.email || '',
            link: `${process.env.REACT_APP_BASE_URL}/cards/${element.id}`,
        });
    });
    return parsedCards;
};

/**
 * Scrape El Ojo de Ugin store results.
 * 
 * @param {string} html - The raw HTML from the search results page
 * @param {string} url - The URL used for this search (for reference in link)
 * @returns {Array} scrapedData - An array of card objects with:
 *   { id, title, price, condition, expansion, imageUrl, link }
 */
function scrapeElOjoDeUgin(html, url) {
    const $ = cheerio.load(html);
    const scrapedData = [];
    // Each product is in an <li class="product"> containing <article class="card">
    $('li.product').each((index, product) => {
      const article = $(product).find('article.card');
       // Title from the card-title <h3> or <a>
      const title = $(product).find('h3.card-title a').text().trim();
  
      // Full category path is in data-product-category, e.g. "Magic the Gathering/Singles/Shards of Alara"
      const fullCategory = article.attr('data-product-category') || '';
      // We'll take the last part after the final slash as the expansion
      let expansion = fullCategory.split('/').pop().trim() || '';
  
      // The price text is in <span class="price">, e.g. "$0.32 - $0.40"
      const priceText = $(product).find('span.price').text().trim() || '$NoPrice';
  
      // If the site also stores numeric price in data-product-price, you can read that:
      // let numericPrice = article.attr('data-product-price') || '';
  
      // Basic check for foil if the title includes "foil"
      let condition = 'No especifica';
  
      // Image: <img ... class="card-image" ...>
      // We'll take the `src` attribute from the first .card-img-container img
      const imageUrl = $(product).find('.card-img-container img').attr('src') || '';
  
      // Link: either from <h3.card-title a> or from <a.card-figure__link>
      // We'll prefer the detail link from the title <a>
      const linkRelative = $(product).find('h3.card-title a').attr('href') || '';
      // Construct a full link if needed:
      const linkAbsolute = linkRelative.startsWith('http')
        ? linkRelative
        : new URL(linkRelative, url).href;
  
      // Generate a unique ID by hashing the entire product HTML
      const productHtml = $(product).html();
      const id = crypto.createHash('sha256').update(productHtml).digest('hex');
  
      // If you need to check for "out of stock" or similar, you can do so here.
      // E.g., skip items that have a specific "Out of stock" marker if present.
  
      // Push the final card object
      scrapedData.push({
        id,
        title,
        price: priceText,
        condition,
        expansion,
        imageUrl,
        link: linkAbsolute,
      });
    });
    return scrapedData;
  }

module.exports = {
    scrapeMTGPirulo,
    scrapeMagicDealers,
    scrapeMagicLair,
    scrapeLaBatikuevaStore,
    parseTheMagicTutor,
    scrapeMercadiaCityStore,
    scrapeMadToyz,
    parseAltobuscadorAPI,
    scrapeElOjoDeUgin
};
