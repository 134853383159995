import axios from 'axios';
import { auth } from './firebase'; // Import Firebase auth instance

const API_URL = process.env.REACT_APP_API_BASE_URL;
const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes before expiration

const axiosWithGoogleToken = axios.create();

const getToken = async () => {
  const user = auth.currentUser;
  if (!user) return null;
  return user.getIdToken();
};

const isTokenExpiringSoon = async () => {
  const user = auth.currentUser;
  if (!user) return true;
  
  try {
    const token = await user.getIdTokenResult();
    const expirationTime = new Date(token.expirationTime).getTime();
    return expirationTime - Date.now() < TOKEN_REFRESH_THRESHOLD;
  } catch (error) {
    console.warn("Error checking token expiration:", error);
    return true;
  }
};

const refreshFirebaseToken = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user');
      return null;
    }
    
    // Force token refresh
    const token = await user.getIdToken(true);
    return token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    handleLogout();
    return null;
  }
};

const handleLogout = async () => {
  try {
    await auth.signOut(); // Use Firebase signOut instead of googleLogout
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
  } catch (error) {
    console.error('Logout error:', error);
  }
};

axiosWithGoogleToken.interceptors.request.use(async (config) => {
  const user = auth.currentUser;
  
  if (!user) {
    console.warn("No authenticated user");
    return config;
  }

  try {
    // Check if token needs refresh
    if (await isTokenExpiringSoon()) {
      console.warn('Token near expiration, refreshing...');
      await refreshFirebaseToken();
    }

    // Get fresh token
    const token = await user.getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  } catch (error) {
    console.error('Error handling token:', error);
    handleLogout();
    return config;
  }
});

export default axiosWithGoogleToken;
export { refreshFirebaseToken };