// src/pages/MyCollectionsList.js
import React, { useState, useEffect } from 'react';
import axiosWithGoogleToken from '../axiosConfig';
import './MyCollectionsList.css';
import { FiEdit, FiTrash } from 'react-icons/fi';

const API_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * TgcCard
 * Renders a single card's cropped preview and edit controls.
 */
const TgcCard = ({
  card,
  previewWidth,
  previewHeight,
  binderUrl,
  onOpenModal,       // function(imageUrl) => void
  onEdit,            // function(cardData) => void
  onDelete,          // function(cardId) => void
  isEditing,         // boolean
  editingData,       // { collectionId, id, name, card_status, ... } or null
  onEditingChange,   // function(newStateOrNull) => void
  onSave,            // function() => void
}) => {
  // The bounding box from the server
  const box = card.collection_box;
  // We measure the actual image dimension so we can scale properly
  const [imgDim, setImgDim] = useState({ width: 0, height: 0 });

  // Handler when the hidden measuring image finishes loading
  const handleImageLoad = (e) => {
    setImgDim({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  // If no binderUrl or no box, show a placeholder
  const noImage = !binderUrl || !box;

  // Compute final scaling based on the measured dimension and the bounding box
  let styleImage = {};
  if (!noImage && imgDim.width > 0 && imgDim.height > 0) {
    // The bounding box is in the coordinate space of the full image (imgDim.width x imgDim.height).
    // We want a final preview of size (previewWidth x previewHeight).
    const scaleX = previewWidth / box.w;
    const scaleY = previewHeight / box.h;

    const finalWidth = imgDim.width * scaleX;
    const finalHeight = imgDim.height * scaleY;

    styleImage = {
      position: 'absolute',
      left: `-${box.x * scaleX}px`,
      top: `-${box.y * scaleY}px`,
      width: `${finalWidth}px`,
      height: `${finalHeight}px`,
    };
  }

  // If editing, we read from editingData; otherwise, from card
  const displayName = isEditing ? editingData.name : card.name;
  const displayStatus = isEditing ? editingData.card_status : card.card_status;

  return (
    <li className="card-item">
      {/* Hidden <img> for dimension measurement */}
      {!noImage && (
        <img
          src={binderUrl}
          alt="measure"
          style={{ display: 'none' }}
          onLoad={handleImageLoad}
        />
      )}

      <div className="card-preview">
        {noImage ? (
          <div className="placeholder">Sin imagen</div>
        ) : (
          <div
            className="cropped-container"
            style={{
              width: previewWidth,
              height: previewHeight,
              overflow: 'hidden',
              position: 'relative',
              border: '1px solid #ccc',
              marginBottom: '5px',
              cursor: 'pointer',
            }}
            onClick={() => onOpenModal(binderUrl)}
          >
            <img src={binderUrl} alt={card.name} style={styleImage} />
          </div>
        )}
      </div>

      <div className="card-info">
        {isEditing ? (
          <div className="card-edit">
            <label>
              Nombre:
              <input
                type="text"
                value={editingData.name}
                onChange={(e) =>
                  onEditingChange({ ...editingData, name: e.target.value })
                }
              />
            </label>
            <label>
              Estado:
              <select
                value={editingData.card_status || 'available'}
                onChange={(e) =>
                  onEditingChange({
                    ...editingData,
                    card_status: e.target.value,
                  })
                }
              >
                <option value="available">Disponible</option>
                <option value="sold">Vendida</option>
                <option value="reserved">Reservada</option>
              </select>
            </label>
            <div className="card-edit-actions">
              <button onClick={onSave} title="Guardar">
                <FiEdit size={16} />
              </button>
              <button onClick={() => onEditingChange(null)} title="Cancelar">
                <FiTrash size={16} />
              </button>
            </div>
          </div>
        ) : (
          <div className="card-view">
            <span className="card-name" onClick={() => onEdit(card)}>
              {displayName}
            </span>
            {displayStatus && <span className="card-status"> - {displayStatus}</span>}
            <div className="card-actions">
              <button onClick={() => onEdit(card)} title="Editar">
                <FiEdit size={16} />
              </button>
              <button onClick={() => onDelete(card.id)} title="Eliminar">
                <FiTrash size={16} />
              </button>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

/**
 * CardCollectionGrid
 * Renders the list of TgcCard for a single collection.
 */
const CardCollectionGrid = ({
  collection,
  previewWidth,
  previewHeight,
  binderIndices,
  onOpenModal,
  onEditCard,
  onDeleteCard,
  editingCard,
  setEditingCard,
  onSaveCard,
}) => {
  const binderIndex = binderIndices[collection.id] || 0;

  return (
    <div className="collection-cards">
      {collection.cards && collection.cards.length > 0 ? (
        <ul className="cards-list">
          {collection.cards.map((card) => (
            <TgcCard
              key={card.id}
              card={card}
              previewWidth={previewWidth}
              previewHeight={previewHeight}
              binderUrl={
                // Use the card's own binder_image_url if it exists;
                // otherwise fallback to the current binder image in the carousel
                card.binder_image_url ||
                (collection.image_urls && collection.image_urls[binderIndex])
              }
              onOpenModal={onOpenModal}
              onEdit={(cardData) => onEditCard(collection.id, cardData)}
              onDelete={(cardId) => onDeleteCard(collection.id, cardId)}
              isEditing={editingCard && editingCard.id === card.id}
              editingData={editingCard}
              onEditingChange={setEditingCard}
              onSave={onSaveCard}
            />
          ))}
        </ul>
      ) : (
        <p>No hay cartas en esta colección.</p>
      )}
    </div>
  );
};

/**
 * MyCollectionsList
 * Main container that fetches the collections and uses CardCollectionGrid to display them.
 */
const MyCollectionsList = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // For collection editing
  const [editingCollectionId, setEditingCollectionId] = useState(null);
  const [editedCollection, setEditedCollection] = useState(null);
  // For card editing (name, status)
  const [editingCard, setEditingCard] = useState(null);
  // Modal for full-size image
  const [modalImage, setModalImage] = useState(null);
  // Binder carousel index per collection
  const [binderIndices, setBinderIndices] = useState({});

  // We want the final cropped card previews to be 150x210
  const previewWidth = 150;
  const previewHeight = 210;

  useEffect(() => {
    const fetchCollections = async () => {
      setLoading(true);
      try {
        const userStr = localStorage.getItem('user');
        if (!userStr) {
          setError('No se encontró usuario logueado.');
          setLoading(false);
          return;
        }
        const user = JSON.parse(userStr);
        const response = await axiosWithGoogleToken.get(
          `${API_URL}/collections?email=${user.email}`
        );
        setCollections(response.data.collections || []);
      } catch (err) {
        console.error('Error al obtener colecciones:', err);
        setError('Error al obtener colecciones');
      }
      setLoading(false);
    };
    fetchCollections();
  }, []);

  // Editing a collection
  const handleEditCollection = (collection) => {
    setEditingCollectionId(collection.id);
    setEditedCollection({ ...collection });
  };

  const handleSaveCollection = async (collectionId) => {
    try {
      const response = await axiosWithGoogleToken.put(
        `${API_URL}/collections/${collectionId}`,
        { collection: editedCollection }
      );
      setCollections((prev) =>
        prev.map((col) => (col.id === collectionId ? response.data : col))
      );
      setEditingCollectionId(null);
      setEditedCollection(null);
    } catch (err) {
      console.error('Error al actualizar la colección:', err);
    }
  };

  const handleDeleteCollection = async (collectionId) => {
    try {
      await axiosWithGoogleToken.delete(`${API_URL}/collections/${collectionId}`);
      setCollections((prev) => prev.filter((col) => col.id !== collectionId));
    } catch (err) {
      console.error('Error al eliminar la colección:', err);
    }
  };

  // Editing a card
  const handleEditCard = (collectionId, card) => {
    setEditingCard({ collectionId, ...card });
  };

  const handleSaveCard = async () => {
    try {
      const { collectionId, id } = editingCard;
      const response = await axiosWithGoogleToken.put(
        `${API_URL}/collections/${collectionId}/cards/${id}`,
        { card: editingCard }
      );
      setCollections((prev) =>
        prev.map((collection) => {
          if (collection.id === collectionId) {
            return {
              ...collection,
              cards: collection.cards.map((c) =>
                c.id === id ? response.data : c
              ),
            };
          }
          return collection;
        })
      );
      setEditingCard(null);
    } catch (err) {
      console.error('Error al actualizar la carta:', err);
    }
  };

  const handleDeleteCard = async (collectionId, cardId) => {
    try {
      await axiosWithGoogleToken.delete(
        `${API_URL}/collections/${collectionId}/cards/${cardId}`
      );
      setCollections((prev) =>
        prev.map((collection) => {
          if (collection.id === collectionId) {
            return {
              ...collection,
              cards: collection.cards.filter((c) => c.id !== cardId),
            };
          }
          return collection;
        })
      );
    } catch (err) {
      console.error('Error al eliminar la carta:', err);
    }
  };

  // Binder carousel
  const handleNextBinder = (collectionId, totalImages) => {
    setBinderIndices((prev) => ({
      ...prev,
      [collectionId]: ((prev[collectionId] || 0) + 1) % totalImages,
    }));
  };

  const handlePrevBinder = (collectionId, totalImages) => {
    setBinderIndices((prev) => ({
      ...prev,
      [collectionId]: (prev[collectionId] - 1 + totalImages) % totalImages,
    }));
  };

  // Modal for full-size image
  const openModalWithImage = (imageUrl) => {
    setModalImage(imageUrl);
  };

  return (
    <div className="my-collections-list">
      <h1>Mis Colecciones</h1>
      {loading && <p>Cargando colecciones...</p>}
      {error && <p className="error">{error}</p>}
      {!loading && collections.length === 0 && (
        <p>No tienes colecciones.</p>
      )}

      {collections.map((collection) => {
        const totalBinderImages = collection.image_urls
          ? collection.image_urls.length
          : 0;
        const binderIndex = binderIndices[collection.id] || 0;
        const binderImageUrl =
          totalBinderImages > 0 ? collection.image_urls[binderIndex] : null;

        return (
          <div key={collection.id} className="collection">
            {editingCollectionId === collection.id ? (
              <div className="collection-edit">
                <label>
                  Nombre Colección:
                  <input
                    type="text"
                    value={editedCollection?.name || ''}
                    onChange={(e) =>
                      setEditedCollection({
                        ...editedCollection,
                        name: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Tags (separados por comas):
                  <input
                    type="text"
                    value={
                      editedCollection?.tags
                        ? editedCollection.tags.join(', ')
                        : ''
                    }
                    onChange={(e) =>
                      setEditedCollection({
                        ...editedCollection,
                        tags: e.target.value
                          .split(',')
                          .map((t) => t.trim())
                          .filter((t) => t),
                      })
                    }
                  />
                </label>
                <button onClick={() => handleSaveCollection(collection.id)}>
                  Guardar
                </button>
                <button onClick={() => setEditingCollectionId(null)}>
                  Cancelar
                </button>
              </div>
            ) : (
              <div className="collection-view">
                <div className="collection-header">
                  <h2>{collection.name}</h2>
                  {totalBinderImages > 0 && (
                    <div className="collection-folder">
                      <div className="binder-carousel">
                        <button
                          onClick={() =>
                            handlePrevBinder(collection.id, totalBinderImages)
                          }
                        >
                          &lt;
                        </button>
                        <img
                          src={binderImageUrl}
                          alt={`Colección ${collection.id} imagen ${
                            binderIndex + 1
                          }`}
                          className="collection-folder-img"
                          onClick={() => openModalWithImage(binderImageUrl)}
                        />
                        <button
                          onClick={() =>
                            handleNextBinder(collection.id, totalBinderImages)
                          }
                        >
                          &gt;
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="collection-actions">
                  <button onClick={() => handleEditCollection(collection)}>
                    <FiEdit size={18} /> Editar Colección
                  </button>
                  <button onClick={() => handleDeleteCollection(collection.id)}>
                    <FiTrash size={18} /> Eliminar Colección
                  </button>
                </div>

                {/* The grid of cards */}
                <CardCollectionGrid
                  collection={collection}
                  previewWidth={previewWidth}
                  previewHeight={previewHeight}
                  binderIndices={binderIndices}
                  onOpenModal={openModalWithImage}
                  onEditCard={handleEditCard}
                  onDeleteCard={handleDeleteCard}
                  editingCard={editingCard}
                  setEditingCard={setEditingCard}
                  onSaveCard={handleSaveCard}
                />
              </div>
            )}
          </div>
        );
      })}

      {/* Full-size image modal */}
      {modalImage && (
        <div className="modal" onClick={() => setModalImage(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={modalImage} alt="Carta agrandada" className="modal-img" />
            <button
              onClick={() => setModalImage(null)}
              className="modal-close"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCollectionsList;
