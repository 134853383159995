// src/components/ProfilePage.js
import React, { useState, useEffect } from 'react';
import axiosWithGoogleToken from '../axiosConfig';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,
  TextField,
  Collapse,
  Select,
  MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import CropImage from './../services/cropImage'; // Use the provided CropImage component
import './ProfilePage.css';

const ProfilePage = ({ user, setUser }) => {
  const [contactInfo, setContactInfo] = useState({
    nickname: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    zone: ''
  });
  const [message, setMessage] = useState('');
  const [reservationRequests, setReservationRequests] = useState([]);
  const [myReservationRequests, setMyReservationRequests] = useState([]);
  const [reservationEdits, setReservationEdits] = useState({}); 

  // Estados para controlar las filas expandidas (collapse)
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedMyRows, setExpandedMyRows] = useState({});

  // Flash message: se oculta después de 3 segundos
  useEffect(() => {
    if(message) {
      const timeout = setTimeout(() => setMessage(''), 3000);
      return () => clearTimeout(timeout);
    }
  }, [message]);

  useEffect(() => {
    console.log("ProfilePage useEffect triggered; user:", user);
    if (user) {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const contactUrl = `${baseUrl}/contact_info`;
      console.log("Fetching contact info from:", contactUrl);
      axiosWithGoogleToken
        .get(contactUrl)
        .then((response) => {
          console.log("Received contact info:", response.data);
          setContactInfo(response.data);
        })
        .catch((error) => {
          console.error("Error fetching contact info:", error);
        });

      // Solicitar todas las reservation requests (mis solicitudes de reserva hechas a mis cartas)
      const reservationUrl = `${baseUrl}/reservation_requests/my_requests`;
      axiosWithGoogleToken
        .get(reservationUrl)
        .then((response) => {
          console.log("Received reservation requests:", response.data);
          setReservationRequests(response.data);
        })
        .catch((error) => {
          console.error("Error fetching reservation requests:", error);
        });

      // Solicitar las reservation requests para mis cartas (hechas por otros)
      const myReservationUrl = `${baseUrl}/reservation_requests/cards_requested_to_me`;
      axiosWithGoogleToken
        .get(myReservationUrl)
        .then((response) => {
          console.log("Received my reservation requests:", response.data);
          setMyReservationRequests(response.data);
        })
        .catch((error) => {
          console.error("Error fetching my reservation requests:", error);
        });
    }
  }, [user]);

  const handleChange = (e) => {
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/contact_info`;
    try {
      const response = await axiosWithGoogleToken.put(url, {
        contact_info: contactInfo
      });
      setUser({ ...user, contact_info: response.data.contact_info });
      setMessage("Perfil actualizado correctamente.");
      console.log("Updated profile response:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage("Error actualizando el perfil.");
    }
  };

  // Funciones para actualizar el estado de las reservation requests (confirmar y rechazar)
  const handleConfirmReservation = async (reqId) => {
    try {
      await axiosWithGoogleToken.put(`${process.env.REACT_APP_API_BASE_URL}/reservation_requests/${reqId}`, {
        status: 'confirmed'
      });
      setMyReservationRequests(prev =>
        prev.map(req => req.id === reqId ? { ...req, status: 'confirmed' } : req)
      );
      setMessage("Reserva confirmada.");
    } catch (error) {
      console.error("Error confirming reservation:", error);
      setMessage("Error al confirmar la reserva.");
    }
  };

  const handleRejectReservation = async (reqId) => {
    try {
      await axiosWithGoogleToken.put(`${process.env.REACT_APP_API_BASE_URL}/reservation_requests/${reqId}`, {
        status: 'rejected'
      });
      setMyReservationRequests(prev =>
        prev.map(req => req.id === reqId ? { ...req, status: 'rejected' } : req)
      );
      setMessage("Reserva rechazada.");
    } catch (error) {
      console.error("Error rejecting reservation:", error);
      setMessage("Error al rechazar la reserva.");
    }
  };

  // Actualiza el estado de edición de una reservation request (para campos adicionales, si fuera necesario)
  const handleReservationEditChange = (reqId, field, value) => {
    setReservationEdits(prev => ({
      ...prev,
      [reqId]: {
        ...prev[reqId],
        [field]: value
      }
    }));
  };

  // Guarda la edición de una reservation request (para cambios en "reservedTo")
  const handleSaveReservationEdit = async (reqId) => {
    try {
      const edit = reservationEdits[reqId];
      await axiosWithGoogleToken.put(`${process.env.REACT_APP_API_BASE_URL}/reservation_requests/${reqId}`, edit);
      setReservationEdits(prev => {
        const newEdits = { ...prev };
        delete newEdits[reqId];
        return newEdits;
      });
      setMessage("Reserva actualizada correctamente.");
    } catch (error) {
      console.error("Error saving reservation edit:", error);
      setMessage("Error actualizando la reserva.");
    }
  };

  // Función para actualizar el estado mediante el desplegable en mis cartas que quieren mis cartas
  const handleStatusChange = async (cardId, reqId, newStatus) => {
    try {
      await axiosWithGoogleToken.put(`${process.env.REACT_APP_API_BASE_URL}/cards/${cardId}/reservation_requests/${reqId}`, { status: newStatus });
      setReservationRequests(prev =>
        prev.map(req => req.id === reqId ? { ...req, status: newStatus } : req)
      );
      setMessage("Estado actualizado correctamente.");
    } catch (error) {
      console.error("Error updating reservation status:", error);
      setMessage("Error actualizando el estado.");
    }
  };

  const toggleRow = (reqId) => {
    setExpandedRows(prev => ({ ...prev, [reqId]: !prev[reqId] }));
  };

  const toggleMyRow = (index) => {
    setExpandedMyRows(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const renderRequesterDetails = (requester) => (
    <div className="collapse-content">
      <p><strong>Nombre:</strong> {requester?.name || 'Desconocido'}</p>
      <p><strong>Email:</strong> {requester?.email || 'No disponible'}</p>
      <p>
        <strong>Contact Info:</strong> {requester?.contact_info ? (
          <>
            {requester.contact_info.nickname && <>Nickname: {requester.contact_info.nickname}<br/></>}
            {requester.contact_info.whatsapp && <>WhatsApp: {requester.contact_info.whatsapp}<br/></>}
            {requester.contact_info.facebook && <>Facebook: {requester.contact_info.facebook}<br/></>}
            {requester.contact_info.instagram && <>Instagram: {requester.contact_info.instagram}<br/></>}
            {requester.contact_info.zone && <>Zona: {requester.contact_info.zone}<br/></>}
          </>
        ) : "No disponible"}
      </p>
    </div>
  );

  return (
    <div className="profile-page">
      <h2>Mi Perfil</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="profile-form">
        <div className="form-group">
          <p>Nombre: {user?.name || ''}</p>
          <p>Email: {user?.email || ''}</p>
          <label htmlFor="nickname">Nombre Público (Nickname):</label>
          <input
            type="text"
            id="nickname"
            name="nickname"
            value={contactInfo.nickname}
            onChange={handleChange}
            placeholder="Tu nombre público"
          />
        </div>
        <div className="form-group">
          <label htmlFor="whatsapp">WhatsApp:</label>
          <input
            type="text"
            id="whatsapp"
            name="whatsapp"
            value={contactInfo.whatsapp}
            onChange={handleChange}
            placeholder="Tu número de WhatsApp"
          />
        </div>
        <div className="form-group">
          <label htmlFor="facebook">Facebook:</label>
          <input
            type="text"
            id="facebook"
            name="facebook"
            value={contactInfo.facebook}
            onChange={handleChange}
            placeholder="Tu perfil de Facebook"
          />
        </div>
        <div className="form-group">
          <label htmlFor="instagram">Instagram:</label>
          <input
            type="text"
            id="instagram"
            name="instagram"
            value={contactInfo.instagram}
            onChange={handleChange}
            placeholder="Tu perfil de Instagram"
          />
        </div>
        <div className="form-group">
          <label htmlFor="zone">Zona Frecuentada:</label>
          <input
            type="text"
            id="zone"
            name="zone"
            value={contactInfo.zone}
            onChange={handleChange}
            placeholder="Pirulo, El Ojo de Ugin, CABA, Tandil, etc"
          />
        </div>
        <button type="submit" className="submit-button">Actualizar Perfil</button>
      </form>

       {/* Agrega un enlace para navegar a la página de Compras y Ventas */}
       <div style={{ marginTop: '1.5rem', textAlign: 'center' }}>
        <Link to="/transactions">Ver mis cartas reservadas</Link>
      </div>
    </div>
  );
};

export default ProfilePage;
