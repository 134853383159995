import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosWithGoogleToken from '../axiosConfig';
import {
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Button
} from '@mui/material';
import './CardsByCollection.css';

// Los colores se mantienen estáticos
const MANA_COLORS = [
  { id: 'blue', name: 'Blue', symbol: 'https://cards.scryfall.io/normal/front/6/8/689dd203-c01d-46de-a8ae-2a3d7d1f6d8f.png' },
  { id: 'black', name: 'Black', symbol: 'https://cards.scryfall.io/normal/front/0/1/01a8f77f-3b55-4f35-a68a-6c8deed1b3d0.png' },
  { id: 'white', name: 'White', symbol: 'https://cards.scryfall.io/normal/front/7/2/72e3fa61-7a10-4e37-bdd1-87965f728b3f.png' },
  { id: 'green', name: 'Green', symbol: 'https://cards.scryfall.io/normal/front/4/2/42c7b6e1-8a21-4dbf-a8d7-61e4f7dbe345.png' },
  { id: 'red', name: 'Red', symbol: 'https://cards.scryfall.io/normal/front/3/5/35e1c8a1-6a2c-4f2c-bd2c-1d7f5c8b9bcd.png' },
  { id: 'colorless', name: 'Colorless', symbol: 'https://cards.scryfall.io/normal/front/5/9/59d8e2fa-2b38-4a3a-8a17-8c2a9a1d4e3f.png' }
];

const CardsByCollectionPage = () => {
  const [collectionsData, setCollectionsData] = useState([]);
  // allowedSets se poblará dinámicamente desde Scryfall
  const [allowedSets, setAllowedSets] = useState([]);
  // selectedSets será un array de objetos (cada uno de allowedSets)
  const [selectedSets, setSelectedSets] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [searchSubmitted, setSearchSubmitted] = useState(false);

  // Manejo de navegación de imágenes en cada colección
  const [collectionImageIndices, setCollectionImageIndices] = useState({});

  // Obtener los sets desde Scryfall (incluyendo su icono)
  useEffect(() => {
    axios
      .get("https://api.scryfall.com/sets")
      .then(response => {
        const sets = response.data.data.map(set => ({
          id: set.code,           // Usamos el código del set como id
          name: set.name,
          logo: set.icon_svg_uri  // URL del ícono del set
        }));
        setAllowedSets(sets);
      })
      .catch(error => console.error("Error fetching allowed sets:", error));
  }, []);

  const handleSearch = () => {
    setSearchSubmitted(true);

    // Combinar ambos multi-select en una única lista de tags
    // Para los sets, extraemos el id de cada objeto seleccionado
    const combinedTags = [
      ...selectedSets.map(s => s.id),
      ...selectedColors
    ];
    const params = {};
    if (combinedTags.length > 0) {
      params.tags = combinedTags;
    }

    axiosWithGoogleToken
      .get(`${process.env.REACT_APP_API_BASE_URL}/collections`, { params })
      .then((response) => {
        setCollectionsData(response.data.collections || []);
      })
      .catch((error) => console.error(error));
  };

  // Navegar imágenes dentro de una colección
  const handleNextImage = (collectionId, totalImages) => {
    setCollectionImageIndices((prev) => ({
      ...prev,
      [collectionId]: ((prev[collectionId] || 0) + 1) % totalImages,
    }));
  };

  const handlePrevImage = (collectionId, totalImages) => {
    setCollectionImageIndices((prev) => ({
      ...prev,
      [collectionId]: (prev[collectionId] - 1 + totalImages) % totalImages,
    }));
  };

  return (
    <div className="collections-page">
      <h1>Ver carpetas</h1>

      {/* Search Form */}
      <div className="search-form">
        {/* Autocomplete para Sets */}
        <Autocomplete
          multiple
          options={allowedSets}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          value={selectedSets}
          onChange={(event, newValue) => setSelectedSets(newValue)}
          renderOption={(props, option) => (
            <li {...props}>
              <img src={option.logo} alt={option.name} className="menu-logo" style={{ width: 24, height: 24, marginRight: 8 }} />
              {option.name}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
                avatar={<img src={option.logo} alt={option.name} className="chip-logo" style={{ width: 20, height: 20 }} />}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label="Sets" placeholder="Escribe para buscar sets" />
          )}
          sx={{ m: 1, minWidth: 250 }}
        />

        {/* Multi-select para colores (se mantiene con Select, por ser estático) */}
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel id="colors-label">Colores</InputLabel>
          <Select
            labelId="colors-label"
            multiple
            value={selectedColors}
            onChange={(e) => setSelectedColors(e.target.value)}
            input={<OutlinedInput label="Colores" />}
            renderValue={(selected) => (
              <div className="selected-chips">
                {selected.map((colorId) => {
                  const colorObj = MANA_COLORS.find(c => c.id === colorId);
                  return (
                    <Chip
                      key={colorId}
                      label={colorObj ? colorObj.name : colorId}
                      avatar={colorObj ? <img src={colorObj.symbol} alt={colorObj.name} className="chip-logo" style={{ width: 20, height: 20 }} /> : null}
                    />
                  );
                })}
              </div>
            )}
          >
            {MANA_COLORS.map((color) => (
              <MenuItem key={color.id} value={color.id}>
                <img src={color.symbol} alt={color.name} className="menu-logo" style={{ width: 24, height: 24, marginRight: 8 }} />
                {color.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button variant="contained" onClick={handleSearch} sx={{ m: 1 }}>
          Search
        </Button>
      </div>

      {/* Mensajes informativos */}
      {!searchSubmitted && (
        <p style={{ marginTop: '20px' }}>
          Selecciona sets y colores y haz clic en "Search".
        </p>
      )}
      {searchSubmitted && collectionsData.length === 0 && (
        <p>No se encontraron colecciones.</p>
      )}

      {/* Renderizar cada colección */}
      {collectionsData.map((collection) => {
        const { id, name, user, cards, image_urls } = collection;
        const currentIndex = collectionImageIndices[id] || 0;
        const totalImages = image_urls?.length || 0;
        const currentImageUrl = totalImages > 0 ? image_urls[currentIndex] : null;

        return (
          <div key={id} className="collection-block">
            <h2>
              Collection {id}
              {name && <span> – {name}</span>}
            </h2>
            {user && (
              <p>
                Created by: {user.name} {user.last_name}
              </p>
            )}

            {/* Navegación de imágenes */}
            {totalImages > 0 ? (
              <div className="collection-image-wrapper">
                <img
                  src={currentImageUrl}
                  alt={`Collection ${id} image`}
                  className="collection-image"
                />
                {totalImages > 1 && (
                  <div className="image-navigation">
                    <button onClick={() => handlePrevImage(id, totalImages)}>
                      Previous
                    </button>
                    <button onClick={() => handleNextImage(id, totalImages)}>
                      Next
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <p>No images for this collection.</p>
            )}

            {/* Listado de cartas */}
            {cards && cards.length > 0 ? (
              <ul className="cards-list">
                {cards.map((card) => (
                  <li key={card.id}>{card.name}</li>
                ))}
              </ul>
            ) : (
              <p>No cards found in this collection.</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CardsByCollectionPage;
