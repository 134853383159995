// src/components/googleLogin/GoogleLoginComponent.js
import React, { useState, useEffect } from 'react';
import { auth, GoogleAuthProvider, signInWithPopup, signOut } from '../../firebase';
import axiosWithGoogleToken from '../../axiosConfig';

const GoogleLoginComponent = ({ onLogin, onLogout }) => {
  const [localUser, setLocalUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        const userData = {
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          picture: user.photoURL
        };
        
        setLocalUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('authToken', token);
        if (onLogin) onLogin(userData);
      } else {
        handleLocalLogout();
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      
      // If you need to send the token to your backend
      const response = await axiosWithGoogleToken.post(
        `${process.env.REACT_APP_API_BASE_URL}/firebase_auth`,
        { firebase_token: token }
      );
      if (response.status !== 200) throw new Error(response.body);
      // Handle any additional backend response here
      
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleLocalLogout = async () => {
    try {
      await signOut(auth);
      setLocalUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      if (onLogout) onLogout();
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div className="login-container">
      {localUser ? (
        <div className="logged-in">
          <h2>Bienvenido, {localUser.name}!</h2>
          <img
            src={localUser.picture}
            alt="Foto de perfil"
            className="profile-picture"
          />
          <button onClick={handleLocalLogout} className="logout-button">
            Logout
          </button>
        </div>
      ) : (
        <div className="login-box">
          <h2>Login con Google</h2>
          <button onClick={handleGoogleLogin} className="google-login-button">
            Sign in with Google
          </button>
        </div>
      )}
    </div>
  );
};

export default GoogleLoginComponent;