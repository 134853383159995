// src/components/SalesPurchasesPage.js
import React, { useState, useEffect } from 'react';
import axiosWithGoogleToken from '../axiosConfig';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button,
  TextField,
  Collapse,
  Select,
  MenuItem
} from '@mui/material';
import CropImage from './../services/cropImage';
import './SalesPurchasesPage.css';

const SalesPurchasesPage = ({ user }) => {
  const [reservationRequests, setReservationRequests] = useState([]);
  const [myReservationRequests, setMyReservationRequests] = useState([]);
  const [reservationEdits, setReservationEdits] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedMyRows, setExpandedMyRows] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (user) {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      // Solicitar solicitudes de reserva que yo hice (mis compras)
      axiosWithGoogleToken.get(`${baseUrl}/reservation_requests/my_requests`)
        .then(response => setMyReservationRequests(response.data))
        .catch(error => console.error("Error fetching my reservation requests:", error));

      // Solicitar solicitudes de reserva para mis cartas (mis ventas)
      axiosWithGoogleToken.get(`${baseUrl}/reservation_requests/cards_requested_to_me`)
        .then(response => setReservationRequests(response.data))
        .catch(error => console.error("Error fetching reservation requests:", error));
    }
  }, [user]);

  // Ejemplo de función para actualizar el estado de una reserva (podrías adaptar según lo necesites)
  const handleStatusChange = async (cardId, reqId, newStatus) => {
    try {
      await axiosWithGoogleToken.put(
        `${process.env.REACT_APP_API_BASE_URL}/cards/${cardId}/reservation_requests/${reqId}`,
        { status: newStatus }
      );
      // Actualizamos localmente (puedes separar la lógica para cada sección)
      setReservationRequests(prev =>
        prev.map(req => req.id === reqId ? { ...req, status: newStatus } : req)
      );
      setMessage("Estado actualizado correctamente.");
    } catch (error) {
      console.error("Error updating reservation status:", error);
      setMessage("Error actualizando el estado.");
    }
  };

  const toggleRow = (reqId) => {
    setExpandedRows(prev => ({ ...prev, [reqId]: !prev[reqId] }));
  };

  const toggleMyRow = (index) => {
    setExpandedMyRows(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const renderRequesterDetails = (requester) => (
    <div className="collapse-content">
      <p><strong>Nombre:</strong> {requester?.name || 'Desconocido'}</p>
      <p><strong>Email:</strong> {requester?.email || 'No disponible'}</p>
      {/* Otros datos de contacto si es necesario */}
    </div>
  );

  return (
    <div className="sales-purchases-page">
      <h2>Mis Compras y Mis Ventas</h2>
      {message && <p className="message">{message}</p>}

      {/* Sección para mis ventas: Solicitudes para mis cartas */}
      <h3>Cartas mías que quieren los demás</h3>
      {reservationRequests.length > 0 ? (
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="Reservation Requests">
            <TableHead>
              <TableRow>
                <TableCell>Miniatura</TableCell>
                <TableCell>Card Name</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mensaje</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservationRequests.map((req) => (
                <React.Fragment key={req.id}>
                  <TableRow>
                    <TableCell>
                      {req.card?.binder_image_url && req.card?.collection_box ? (
                        <CropImage 
                          binderUrl={req.card.binder_image_url}
                          box={req.card.collection_box}
                          previewWidth={50}
                          previewHeight={70}
                          onClick={() => window.open(req.card.binder_image_url, '_blank')}
                        />
                      ) : 'Sin imagen'}
                    </TableCell>
                    <TableCell>{req.card?.name || 'Carta desconocida'}</TableCell>
                    <TableCell 
                      onClick={() => toggleRow(req.id)} 
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                      {req.requester?.name || 'Desconocido'}
                    </TableCell>
                    <TableCell>{req.requester?.email || ''}</TableCell>
                    <TableCell>{req.message}</TableCell>
                    <TableCell>
                      <Select
                        value={req.status || 'pending'}
                        onChange={(e) => handleStatusChange(req.card.id, req.id, e.target.value)}
                      >
                        <MenuItem value="pending">Pendiente</MenuItem>
                        <MenuItem value="accepted">Accepted</MenuItem>
                        <MenuItem value="payed">Payed</MenuItem>
                        <MenuItem value="delivered">Delivered</MenuItem>
                        <MenuItem value="rejected">Rejected</MenuItem>
                        <MenuItem value="canceled">Canceled</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      {req.status !== 'reserved' && (
                        <>
                          <Button variant="contained" color="primary" style={{ marginRight: '0.5rem' }}>
                            Confirmar
                          </Button>
                          <Button variant="contained" color="secondary" style={{ marginRight: '0.5rem' }}>
                            Rechazar
                          </Button>
                        </>
                      )}
                      {/* Si necesitas edición adicional, agregar campos y botones */}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                      <Collapse in={expandedRows[req.id]} timeout="auto" unmountOnExit>
                        {renderRequesterDetails(req.requester)}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>No hay solicitudes de reserva hechas.</p>
      )}

      {/* Sección para mis compras: Solicitudes que yo hice */}
      <h3>Cartas que quiero</h3>
      {myReservationRequests.length > 0 ? (
        <TableContainer component={Paper} className="table-container">
          <Table aria-label="My Reservation Requests">
            <TableHead>
              <TableRow>
                <TableCell>ID de Carta</TableCell>
                <TableCell>Nombre de Carta</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mensaje</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myReservationRequests.map((req, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{req.card?.id}</TableCell>
                    <TableCell>{req.card?.name || "Carta desconocida"}</TableCell>
                    <TableCell 
                      onClick={() => toggleMyRow(index)} 
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                    >
                      {req.requester?.name || 'Desconocido'}
                    </TableCell>
                    <TableCell>{req.requester?.email || ''}</TableCell>
                    <TableCell>{req.message}</TableCell>
                    <TableCell>{req.status || 'Pendiente'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedMyRows[index]} timeout="auto" unmountOnExit>
                        {renderRequesterDetails(req.requester)}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p>No hay solicitudes de reserva para tus cartas.</p>
      )}
    </div>
  );
};

export default SalesPurchasesPage;
