// CardsByCollectionPage.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import axiosWithGoogleToken from '../axiosConfig';
import {
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './CardsByCollection.css';

const MANA_COLORS = [
  { id: 'blue', name: 'Blue', symbol: 'https://cards.scryfall.io/normal/front/6/8/689dd203-c01d-46de-a8ae-2a3d7d1f6d8f.png' },
  { id: 'black', name: 'Black', symbol: 'https://cards.scryfall.io/normal/front/0/1/01a8f77f-3b55-4f35-a68a-6c8deed1b3d0.png' },
  { id: 'white', name: 'White', symbol: 'https://cards.scryfall.io/normal/front/7/2/72e3fa61-7a10-4e37-bdd1-87965f728b3f.png' },
  { id: 'green', name: 'Green', symbol: 'https://cards.scryfall.io/normal/front/4/2/42c7b6e1-8a21-4dbf-a8d7-61e4f7dbe345.png' },
  { id: 'red', name: 'Red', symbol: 'https://cards.scryfall.io/normal/front/3/5/35e1c8a1-6a2c-4f2c-bd2c-1d7f5c8b9bcd.png' },
  { id: 'colorless', name: 'Colorless', symbol: 'https://cards.scryfall.io/normal/front/5/9/59d8e2fa-2b38-4a3a-8a17-8c2a9a1d4e3f.png' }
];

const CardsByCollectionPage = () => {
  const [collectionsData, setCollectionsData] = useState([]);
  const [allowedSets, setAllowedSets] = useState([]);
  const [selectedSets, setSelectedSets] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [collectionImageIndices, setCollectionImageIndices] = useState({});
  const imageRef = useRef(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [fullscreenCollectionId, setFullscreenCollectionId] = useState(null);
  const [showCardList, setShowCardList] = useState(false);
  const [touchStartX, setTouchStartX] = useState(0);
  const [showListInFullscreen, setShowListInFullscreen] = useState(false);

  const originalWidth = 600;
  const originalHeight = 800;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageLoad = (e) => {
    setImgWidth(e.target.clientWidth);
    setImgHeight(e.target.clientHeight);
  };

  useEffect(() => {
    axios.get("https://api.scryfall.com/sets")
      .then(response => {
        const sets = response.data.data.map(set => ({
          id: set.code,
          name: set.name,
          logo: set.icon_svg_uri
        }));
        setAllowedSets(sets);
      })
      .catch(error => console.error("Error fetching allowed sets:", error));
  }, []);

  const handleSearch = () => {
    setSearchSubmitted(true);
    const params = { tags: [...selectedSets.map(s => s.id), ...selectedColors] };
    axiosWithGoogleToken.get(`${process.env.REACT_APP_API_BASE_URL}/collections`, { params })
      .then((response) => {
        setCollectionsData(response.data.collections || []);
        setSelectedCard(null);
        const indices = {};
        response.data.collections.forEach(col => {
          if (col.image_urls?.length > 0) indices[col.id] = 0;
        });
        setCollectionImageIndices(indices);
      })
      .catch((error) => console.error(error));
  };

  const handleNextImage = (collectionId, totalImages) => {
    setCollectionImageIndices(prev => ({
      ...prev,
      [collectionId]: ((prev[collectionId] || 0) + 1) % totalImages,
    }));
  };

  const handlePrevImage = (collectionId, totalImages) => {
    setCollectionImageIndices(prev => ({
      ...prev,
      [collectionId]: (prev[collectionId] - 1 + totalImages) % totalImages,
    }));
  };

  const handleCardClick = (card, collectionId) => {
    setSelectedCard(card);
    if (card.collection_box?.number !== undefined) {
      setCollectionImageIndices(prev => ({
        ...prev,
        [collectionId]: card.collection_box.number
      }));
    }
    if (isMobile) setShowCardList(false);
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e, collection) => {
    const touchEndX = e.changedTouches[0].clientX;
    const deltaX = touchEndX - touchStartX;
    const totalImages = collection.image_urls?.length || 0;

    if (Math.abs(deltaX) > 50) {
      deltaX > 0 
        ? handlePrevImage(collection.id, totalImages)
        : handleNextImage(collection.id, totalImages);
    }
  };

  const renderDesktopCollection = (collection) => {
    const { id, name, user, cards, image_urls } = collection;
    const currentIndex = collectionImageIndices[id] || 0;
    const totalImages = image_urls?.length || 0;
    const binderImageUrl = image_urls?.[currentIndex];

    return (
      <div key={id} className="collection-block">
        <h2>Collection {id}{name && <span> – {name}</span>}</h2>
        {user && <p>Created by: {user.name} {user.last_name}</p>}
        <div className="collection-layout">
          <div className="collection-cards-list">
            {cards?.length > 0 ? (
              <ul>
                {cards.map((card) => (
                  <li
                    key={card.id}
                    className="card-list-item"
                    onMouseEnter={() => setHoveredCard(card)}
                    onMouseLeave={() => setHoveredCard(null)}
                    onClick={() => handleCardClick(card, id)}
                  >
                    <div className="card-list-item-content">
                      <strong>{card.name}</strong>
                      {card.card_status !== 'available' && (
                        <span className="unavailable-overlay">&nbsp;(No disponible)</span>
                      )}
                    </div>
                    {card.card_status === 'available' && (
                      <Button variant="outlined" size="small" onClick={(e) => e.stopPropagation()}>
                        Reservar
                      </Button>
                    )}
                  </li>
                ))}
              </ul>
            ) : <p>No cards found in this collection.</p>}
          </div>
          <div className="collection-binder-image">
            {totalImages > 0 ? (
              <div className="image-wrapper">
                <img
                  ref={imageRef}
                  src={binderImageUrl}
                  alt={`Binder image for collection ${id}`}
                  className="big-binder-image"
                  onLoad={handleImageLoad}
                />
                {selectedCard?.collection_id === id && (
                  <div className="highlight-box" style={{
                    left: (selectedCard.collection_box.x / originalWidth) * imgWidth,
                    top: (selectedCard.collection_box.y / originalHeight) * imgHeight,
                    width: (selectedCard.collection_box.w / originalWidth) * imgWidth,
                    height: (selectedCard.collection_box.h / originalHeight) * imgHeight
                  }} />
                )}
                <div className="image-pagination-controls">
                  <Button onClick={() => handlePrevImage(id, totalImages)}>Previous</Button>
                  <span>Image {currentIndex + 1} of {totalImages}</span>
                  <Button onClick={() => handleNextImage(id, totalImages)}>Next</Button>
                </div>
              </div>
            ) : <p>No binder images for this collection.</p>}
          </div>
        </div>
      </div>
    );
  };

  const renderMobileCollection = (collection) => {
    const { id, name, user, cards, image_urls } = collection;
    const currentIndex = collectionImageIndices[id] || 0;
    const binderImageUrl = image_urls?.[currentIndex];

    return (
      <div key={id} className="collection-block mobile">
        <h2>Collection {id}{name && <span> – {name}</span>}</h2>
        {user && <p>Created by: {user.name} {user.last_name}</p>}
        <div className="mobile-layout">
          <div className="mobile-binder-image" onClick={() => setFullscreenCollectionId(id)}>
            {binderImageUrl ? (
              <div className="image-wrapper">
                <img
                  src={binderImageUrl}
                  alt={`Binder image for collection ${id}`}
                  className="big-binder-image"
                />
                <div className="image-pagination-controls">
                  <Button onClick={(e) => {
                    e.stopPropagation();
                    handlePrevImage(id, image_urls.length);
                  }}>Previous</Button>
                  <span>Image {currentIndex + 1} of {image_urls.length}</span>
                  <Button onClick={(e) => {
                    e.stopPropagation();
                    handleNextImage(id, image_urls.length);
                  }}>Next</Button>
                </div>
              </div>
            ) : <p>No binder image available.</p>}
          </div>
          <Button 
            className="floating-cardlist-btn" 
            onClick={() => setShowCardList(!showCardList)}
          >
            {showCardList ? "Cerrar Lista" : "Ver Lista de Cartas"}
          </Button>
          {showCardList && (
            <div className="mobile-cardlist-panel">
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Lista de Cartas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {cards?.length > 0 ? (
                    <List>
                      {cards.map((card) => (
                        <ListItem
                          button
                          key={card.id}
                          onClick={() => handleCardClick(card, id)}
                        >
                          <ListItemText
                            primary={card.name}
                            secondary={card.card_status !== 'available' && "No disponible"}
                          />
                          {card.card_status === 'available' && (
                            <Button variant="outlined" size="small">
                              Reservar
                            </Button>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  ) : <Typography>No cards found in this collection.</Typography>}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFullscreenOverlay = (collection) => {
    const { id, image_urls, cards } = collection;
    const currentIndex = collectionImageIndices[id] || 0;
    const binderImageUrl = image_urls?.[currentIndex];

    return (
      <div 
        className="fullscreen-overlay"
        onTouchStart={handleTouchStart}
        onTouchEnd={(e) => handleTouchEnd(e, collection)}
      >
        <div className="fullscreen-controls-top">
          <Button 
            className="close-fullscreen-btn" 
            onClick={() => setFullscreenCollectionId(null)}
            variant="contained"
          >
            X
          </Button>
          <Button
            className="toggle-list-btn"
            onClick={() => setShowListInFullscreen(!showListInFullscreen)}
            variant="contained"
          >
            {showListInFullscreen ? 'Ocultar lista' : 'Ver lista'}
          </Button>
        </div>

        <div className="fullscreen-image-wrapper">
          {binderImageUrl && (
            <img
              src={binderImageUrl}
              alt="Full screen binder"
              className="fullscreen-image"
              onLoad={handleImageLoad}
            />
          )}
          {selectedCard?.collection_id === id && (
            <div
              className="highlight-box"
              style={{
                left: (selectedCard.collection_box.x / originalWidth) * imgWidth,
                top: (selectedCard.collection_box.y / originalHeight) * imgHeight,
                width: (selectedCard.collection_box.w / originalWidth) * imgWidth,
                height: (selectedCard.collection_box.h / originalHeight) * imgHeight
              }}
            />
          )}
        </div>

        <div className={`card-list-fullscreen ${showListInFullscreen ? 'visible' : ''}`}>
          <List className="card-list-content">
            {cards?.map((card) => (
              <ListItem
                key={card.id}
                button
                onClick={() => {
                  handleCardClick(card, id);
                  setShowListInFullscreen(false);
                }}
              >
                <ListItemText
                  primary={card.name}
                  secondary={card.card_status !== 'available' && "No disponible"}
                />
              </ListItem>
            ))}
          </List>
        </div>

        <div className="fullscreen-pagination">
          <span>Imagen {currentIndex + 1} de {image_urls.length}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="collections-page">
      <h1>Ver carpetas</h1>

      <div className="search-form">
        <Autocomplete
          multiple
          options={allowedSets}
          getOptionLabel={(option) => option.name}
          value={selectedSets}
          onChange={(_, newValue) => setSelectedSets(newValue)}
          renderTags={() => null}
          renderOption={(props, option) => (
            <li {...props}>
              <img src={option.logo} alt={option.name} style={{ width: 24, height: 24, marginRight: 8 }} />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Sets" placeholder="Escribe para buscar sets" />
          )}
          sx={{ m: 1, minWidth: 250 }}
        />
        <div className="selected-sets-chips">
          {selectedSets.map((option) => (
            <Chip
              key={option.id}
              label={option.name}
              onDelete={() => setSelectedSets(selectedSets.filter(s => s.id !== option.id))}
              avatar={<img src={option.logo} alt={option.name} style={{ width: 20, height: 20 }} />}
              sx={{ marginRight: 1, marginBottom: 1 }}
            />
          ))}
        </div>
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel>Colores</InputLabel>
          <Select
            multiple
            value={selectedColors}
            onChange={(e) => setSelectedColors(e.target.value)}
            renderValue={(selected) => (
              <div className="selected-chips">
                {selected.map((colorId) => {
                  const color = MANA_COLORS.find(c => c.id === colorId);
                  return (
                    <Chip
                      key={colorId}
                      label={color?.name}
                      avatar={<img src={color?.symbol} alt={color?.name} style={{ width: 20, height: 20 }} />}
                    />
                  );
                })}
              </div>
            )}
          >
            {MANA_COLORS.map((color) => (
              <MenuItem key={color.id} value={color.id}>
                <img src={color.symbol} alt={color.name} style={{ width: 24, height: 24, marginRight: 8 }} />
                {color.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSearch} sx={{ m: 1 }}>
          Search
        </Button>
      </div>

      {!searchSubmitted && <p style={{ marginTop: '20px' }}>Selecciona sets y colores y haz clic en "Search".</p>}
      {searchSubmitted && collectionsData.length === 0 && <p>No se encontraron colecciones.</p>}

      {collectionsData.map((collection) =>
        isMobile ? renderMobileCollection(collection) : renderDesktopCollection(collection)
      )}

      {fullscreenCollectionId && renderFullscreenOverlay(
        collectionsData.find(c => c.id === fullscreenCollectionId)
      )}
    </div>
  );
};

export default CardsByCollectionPage;