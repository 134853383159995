// src/components/DeckSearch.js
import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { CircularProgress, Chip, Button } from '@mui/material';
import { CloudUpload, Warning, ShoppingCart, FilterList, Sort } from '@mui/icons-material';
import {
  scrapeMTGPirulo,
  scrapeMagicDealers,
  scrapeLaBatikuevaStore,
  scrapeMagicLair,
  scrapeMercadiaCityStore,
  scrapeMadToyz,
  scrapeElOjoDeUgin
} from '../scrappers/scraper';
import './DeckSearch.css';
import logoImage from '../buscaadorLogo.png'; // Replace with your logo image path
import { postFirestore } from '../services/firestoreService';

// const PROXY_URL = 'https://corsproxy.io/';
const PROXY_URL = 'https://api.allorigins.win/raw?url='
const VENDORS = [
  { id: 'pirulo', name: 'MTG Pirulo', logo: 'https://acdn-us.mitiendanube.com/stores/004/784/893/themes/common/logo-440470619-1721329667-50b1486da153045efa155498623d81ef1721329667.png?0' },
  { id: 'magicDealers', name: 'Magic Dealers', logo: 'https://cc-client-assets.nyc3.cdn.digitaloceanspaces.com/store/magicdealersstore/34ed8d4113fa4c588bc971138c8eebe2/large/MagicDealers-Logo.png' },
  { id: 'batikueva', name: 'La Batikueva', logo: 'https://acdn-us.mitiendanube.com/stores/001/180/089/themes/common/logo-1153802043-1706371153-32e2de2e952190f4cd6100029359c6551706371154.png?0' },
  { id: 'magicLair', name: 'Magic Lair', logo: 'https://www.magiclair.com.ar/cdn/shop/files/Sin_titulo-1.png?v=1696865195' },
  { id: 'mercadiaCity', name: 'Mercadia City', logo: 'https://mercadiacity.com/pub/media/logo/websites/1/Imagotipo_Negro_-_Mercadia_City.png' },
  { id: 'madToyz', name: 'Mad Toyz', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiwI3old0RD9VI9n6DTTwG84wjuT2GtofnXQ&s' },
  { id: 'ojoDeUgin', name: 'Ojo de Ugin', logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXqr3gN6Od-T4lhl9jrtneZLWSik76wTJREw&s' },
];

const DeckSearch = () => {
  const [selectedVendors, setSelectedVendors] = useState(['pirulo', 'magicDealers']);
  const [fileCards, setFileCards] = useState([]);
  const [results, setResults] = useState({});
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [viewMode, setViewMode] = useState('grid'); // "grid" or "list"
  const fileInputRef = useRef(null);

  // Handle file upload using simple file input.
  const handleFileUpload = useCallback((event) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];
    const reader = new FileReader();
    
    reader.onload = (e) => {
      const cards = e.target.result
        .split('\n')
        .filter(line => line.trim())
        .map(line => {
          const [quantity, ...nameParts] = line.trim().split(' ');
          return {
            quantity: parseInt(quantity) || 1,
            name: nameParts.join(' ').trim(),
            key: `${nameParts.join(' ').trim()}-${Date.now()}`
          };
        });
      setFileCards(cards);
    };
    
    reader.readAsText(file);
  }, []);

  const processCard = async (card, vendors) => {
    const cardResults = [];
    for (const vendor of vendors) {
      try {
        const url = getVendorUrl(vendor.id, card.name);
        const response = await axios.get(`${PROXY_URL}${encodeURIComponent(url)}`);
        const scrapedData = await scrapeVendorData(vendor.id, response.data);
        const processResults = scrapedData
          .map(item => ({
            ...item,
            vendor: vendor.id,
            exactMatch: item.title.toLowerCase() === card.name.toLowerCase(),
            partialMatch: item.title.toLowerCase().includes(card.name.toLowerCase())
          }))
          .filter(item => item.exactMatch || item.partialMatch)
          .sort((a, b) => a.exactMatch - b.exactMatch);
        cardResults.push(...processResults);
      } catch (error) {
        console.error(`Error processing ${vendor.name}:`, error);
      }
      setProgress(prev => prev + (100 / (fileCards.length * vendors.length)));
    }
    return cardResults;
  };

  const handleBulkSearch = async () => {
    setProcessing(true);
    setProgress(0);
    postFirestore('bulkSearch', {}, selectedVendors );
    const activeVendors = VENDORS.filter(v => selectedVendors.includes(v.id));
    const resultsObj = {};
    for (const card of fileCards) {
      const cardResults = await processCard(card, activeVendors);
      resultsObj[card.key] = {
        ...card,
        results: cardResults,
        bestPrice: cardResults.reduce((min, curr) => curr.price < min ? curr.price : min, Infinity)
      };
      setResults(prev => ({ ...prev, [card.key]: resultsObj[card.key] }));
    }
    setProcessing(false);
  };

  const getVendorUrl = (vendorId, cardName) => {
    const urls = {
      pirulo: `https://www.mtgpirulo.com/products/search?q=${encodeURIComponent(cardName)}`,
      magicDealers: `https://www.magicdealersstore.com/products/search?q=${encodeURIComponent(cardName)}`,
      batikueva: `https://www.labatikuevastore.com/search/?q=${encodeURIComponent(cardName)}`,
      magicLair: `https://magic-lair.myshopify.com/search?q=${encodeURIComponent(cardName)}`,
      mercadiaCity: `https://mercadiacity.com/index.php/catalogsearch/result/?q=${encodeURIComponent(cardName)}`,
      madToyz: `https://madtoyzjugueteria.com/?s=${encodeURIComponent(cardName)}`,
      ojoDeUgin: `https://elojodeugin.com/search.php?search_query=${encodeURIComponent(cardName)}`
    };
    return urls[vendorId];
  };

  const getVendorCurrency = (vendorId, cardName) => {
    const urls = {
      pirulo: 'USD',
      magicDealers: 'ARS',
      batikueva: 'ARS',
      magicLair: 'ARS',
      mercadiaCity: 'ARS',
      madToyz: 'ARS',
      ojoDeUgin: 'USD',
    };
    return urls[vendorId];
  };

  const scrapeVendorData = (vendorId, html) => {
    const scrapers = {
      pirulo: () => scrapeMTGPirulo(html),
      magicDealers: () => scrapeMagicDealers(html),
      batikueva: () => scrapeLaBatikuevaStore(html),
      magicLair: () => scrapeMagicLair(html),
      mercadiaCity: () => scrapeMercadiaCityStore(html),
      madToyz: () => scrapeMadToyz(html),
      ojoDeUgin: () => scrapeElOjoDeUgin(html)
    };
    return scrapers[vendorId]?.() || [];
  };

  // Preview dimensions for cropped card images
  const previewWidth = 150;
  const previewHeight = 210;

  return (
    <div className="bulk-search-container">
      <h2>Búsqueda MASIVA Bro'</h2>
      <div className="upload-section">
        <div className="file-upload">
          <CloudUpload className="upload-icon" />
          <p>
            Seleccioná tu archivo .txt: 
            <input
              type="file"
              accept=".txt"
              onChange={handleFileUpload}
              ref={fileInputRef}
            />
          </p>
          <small>Formato: 1 Nombre de Carta por línea</small>
        </div>

        <div className="vendor-selection">
          <h3>Proveedores:</h3>
          <div className="vendor-grid">
            {VENDORS.map(vendor => (
              <Chip
                key={vendor.id}
                label={vendor.name}
                avatar={<img src={vendor.logo} alt="" className="vendor-logo" />}
                clickable
                color={selectedVendors.includes(vendor.id) ? 'primary' : 'default'}
                onClick={() => setSelectedVendors(prev => 
                  prev.includes(vendor.id)
                    ? prev.filter(id => id !== vendor.id)
                    : [...prev, vendor.id]
                )}
              />
            ))}
          </div>
        </div>
      </div>
      <Button 
            variant="contained" 
            color="primary" 
            onClick={handleBulkSearch} 
            disabled={processing}
          >
            Iniciar búsqueda
          </Button>
      <div className="view-toggle">
        <Button variant="outlined" onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
          Cambiar a {viewMode === 'grid' ? 'Vista Lista' : 'Vista Cuadrícula'}
        </Button>
      </div>

      {processing && (
        <div className="progress-overlay">
          <CircularProgress 
            variant="determinate" 
            value={progress} 
            size={80}
            thickness={4}
          />
          <div className="progress-text">
            <h3>Buscando en {selectedVendors.length} proveedores</h3>
            <p>{Math.round(progress)}% completado</p>
            <img src={logoImage} alt="Procesando imagenes..." className="loader-image" />
          </div>
        </div>
      )}

      <div className="results-section">
        <div className="results-header">
          <h2>{Object.keys(results).length} resultados encontrados</h2>
          <div className="controls">
            {/* <Button variant="outlined" startIcon={<Sort />}>
              Ordenar
            </Button>
            <Button variant="outlined" startIcon={<FilterList />}>
              Filtrar
            </Button> */}
          </div>
        </div>

        {viewMode === 'grid' ? (
          <div className="results-grid">
            {Object.values(results).map(card => (
              <div key={card.key} className="card-group">
                <div className="card-header">
                  <h4>{card.name}</h4>
                  <Chip label={`${card.quantity}x necesarios`} size="small" />
                </div>
                
                {card.results.length > 0 ? (
                  <div className="offers-list">
                    {card.results.map((offer, index) => (
                      <div 
                        key={index}
                        className={`offer-card ${offer.exactMatch ? 'exact' : 'partial'}`}
                      >
                        <div className="offer-main">
                          <img 
                            src={VENDORS.find(v => v.id === offer.vendor).logo} 
                            alt="" 
                            className="vendor-logo" 
                          />
                          <div className="offer-details">
                            <span className="price">${offer.price}</span>
                            <span className="vendor">{offer.vendor}</span>
                          </div>
                        </div>
                        <div className="offer-meta">
                          {offer.stock && (
                            <span className="stock">{offer.stock} disponibles</span>
                          )}
                          {!offer.exactMatch && (
                            <Warning className="warning-icon" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-results">
                    No se encontraron resultados para esta carta
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          // List view: group by card and display vendors as simple text lines
          <div className="results-list">
            {Object.values(results).map(card => (
              <div key={card.key} className="list-group">
                <h4>{card.name} <Chip label={`${card.quantity}x`} size="small" /></h4>
                <ul className="list-offers">
                  {card.results.map((offer, index) => (
                    <li key={index}>
                      {VENDORS.find(v => v.id === offer.vendor)?.name}: {getVendorCurrency(VENDORS.find(v => v.id === offer.vendor)?.name)}{offer.price}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeckSearch;
