import React from 'react';
import './CardList.css';

const CardList = ({ title, searchUrl, isLoading, cards, placeholderImage, emptyMessage, currencySymbol, savedCards, updateSavedCards }) => {
  const toggleSaveCard = (card) => {
    const currentSaved = savedCards[title] || [];
    const isSaved = currentSaved.some((savedCard) => savedCard.id === card.id);
    const updatedSaved = isSaved
      ? currentSaved.filter((savedCard) => savedCard.id !== card.id)
      : [...currentSaved, card];

    updateSavedCards({ ...savedCards, [title]: updatedSaved });
  };
  return (
    <div className="card-list-container">
      <h2 className="card-list-title">{title}</h2>
      {searchUrl && (
        <a href={searchUrl} target="_blank" rel="noopener noreferrer" className="search-link">
          Ver la búsqueda en este sitio
        </a>
      )}
      {isLoading ? (
        <div>
          <img src={placeholderImage} alt="Loading..." className="loader-image" />
          <p>Cargando...</p>
        </div>
      ) : (
        <>
          { cards.length > 0 ? (
            <div className="cards">
              {cards.map((card, index) => (
                <div key={index} className="card-item">
                  <a id={card.id} href={card.link} target="_blank" rel="noopener noreferrer">
                    <div className="card-image-container">
                      <img
                        src={card.imageUrl || placeholderImage}
                        alt={card.title}
                        className="card-image"
                      />
                    </div>
                    <p className="card-title">{card.title}</p>
                    <p> {card.condition || 'No especifica condición'}</p>
                    <p> {card.expansion || 'No especifica expansión'}</p>
                    <p className="card-price">
                      {currencySymbol}
                      {card.price}
                    </p>
                    <p>{
                      card.collectionUrl && (
                        <a href={card.collectionUrl} target="_blank" rel="noopener noreferrer">
                          Ver en la colección
                        </a>
                      )
                    }
                    </p>
                    <p>
                    {
                      card.vendorUrl && (
                        <a href={card.vendorUrl} target="_blank" rel="noopener noreferrer">
                          Ver info vendedor{card.vendorName ? `: ${card.vendorName}` : ''} 
                        </a>
                      )
                    }
                    </p>
                    <p> {card.expansion || 'No especifica expansión'}</p>
                  </a>
                  <button onClick={() => toggleSaveCard(card)} className="save-button">
                    {savedCards[title]?.some((savedCard) => savedCard.id === card.id)
                      ? 'Remove'
                      : 'Save'}
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty">{emptyMessage}</div>
          )}
        </>
      )}
    </div>
  );
};

export default CardList;