import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

const saveData = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "userActions"), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const postFirestore = async (action, input, selections) => {
    console.log('Abount to sabe action:', action, 'input:', input, 'selections:', selections);
    const data = {
      action,
      input,
      timestamp: new Date().toISOString(),
      selections,
      sessionId: localStorage.getItem("sessionId"), // Use this to identify the session/computer
    };

    saveData(data);
  };

  export { postFirestore };